import {CommonModule} from '@angular/common'
import {NgModule} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatButtonModule} from '@angular/material/button'
import {MatDialogModule} from '@angular/material/dialog'
import {MatIconModule} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MatMenuModule} from '@angular/material/menu'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {CommonRoutingModule} from './common-routing.module'
import {HeaderComponent} from './header/header.component'
import {KommunSelectorComponent} from './kommun-selector/kommun-selector.component'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    KommunSelectorComponent,
    WaitDialogComponent
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [
    HeaderComponent,
    BreadcrumbsComponent,
    WaitDialogComponent,
    KommunSelectorComponent
  ]
})
export class SpbCommonModule {
}
