import {Inject, Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {BehaviorSubject, Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {ILoanPromiseAdmin, IUser, LoanPromiseListItem, STORED_APPLICATION_NAME} from '../application/data-types'
import {map} from 'rxjs/operators'
import {HelperService, TokenPayload} from '@sparbanken-syd/sparbanken-syd-bankid'
import {IApplicationResult} from '@sparbanken-syd/loan-backend'

/**
 * Holds various configuration data
 */
export interface SpbConfiguration {
  /**
   * Indicates if the signed-in user is admin
   */
  admin: boolean

  /**
   * Show UC in form to bypass UC
   */
  mockUC: boolean
}

export interface Payload extends TokenPayload {
  exp?: number
  admin?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  public users$: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([])
  /**
   * Interested parties may subscribe to this to have a short list
   * of loan promises to look at.
   */
  public loanPromiseList: BehaviorSubject<LoanPromiseListItem[]> = new BehaviorSubject<LoanPromiseListItem[]>([])
  /**
   * Extra options for the http requests. Typically, the authorization header.
   */
  private httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'no-good'
    })
  }
  /**
   *
   */
  private spbConfiguration = {admin: false, mockUC: true}
  /**
   * Subscribe to the admin state
   */
  private configuration = new BehaviorSubject<SpbConfiguration>(this.spbConfiguration)
  public configState = this.configuration.asObservable()
  /**
   * Keep a local representation of all loan promises
   */
  private loanPromises: LoanPromiseListItem[] = []

  /**
   * When was the last time we fetched data (loan promise list)
   */
  private lastFetchTime: number = 0

  constructor(
    private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  /**
   * Set the authentication token
   * @param token - The token as received from the login service
   */
  public setToken(token: string): void {
    const payload = HelperService.GetTokenPayload(token) as Payload
    const tokenTimeStamp = payload.exp
    const loginState = {
      token,
      timeStamp: tokenTimeStamp
    }
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', `Bearer ${loginState.token}`)
    this.injectedLocalStorage.setItem('accessToken', JSON.stringify(loginState))
    this.isAdmin()
  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken = (): void => {
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'no-good')
    this.injectedLocalStorage.removeItem('accessToken')
    this.isAdmin()
  }

  /**
   * Reset/remove the set application in localstorage. Does not reset the application template in HTML
   */
  public resetApplication = (): void => {
    this.injectedLocalStorage.removeItem(STORED_APPLICATION_NAME)
  }

  /**
   * Reset/change the application personnummer for easy "back" w/o redirect to an existing
   * application
   */
  public resetApplicationPersonnummer = (): void => {
    const loginStateString = this.injectedLocalStorage.getItem(STORED_APPLICATION_NAME) as string
    const application = JSON.parse(loginStateString)
    application.applicants[0].personNummer = ''
    this.injectedLocalStorage.setItem(STORED_APPLICATION_NAME, JSON.stringify(application))
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): boolean {
    const loginStateString = this.injectedLocalStorage.getItem('accessToken')
    if (loginStateString) {
      const loginState = JSON.parse(loginStateString)
      if (HelperService.GetTokenPayload(loginState.token)) {
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', `Bearer ${loginState.token}`)
        this.isAdmin()
        return true
      }
    }
    this.resetToken()
    return false
  }

  /**
   * Turns UC mocking on or off.
   */
  public toggleUC() {
    this.spbConfiguration.mockUC = !this.spbConfiguration.mockUC
    this.configuration.next(this.spbConfiguration)
  }

  /**
   * Create an application
   */
  public apply(application: any, soft = false): Observable<any> {
    const url = `${environment.loanServiceUrl}/promise/${soft ? 'submit' : 'apply'}`
    return this.httpClient.post<any>(url, application, this.httpOptions)
  }

  /**
   * Fetch existing loan promise
   * @param id - The ID of the promise
   */
  public fetch(id: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/promise/${id}`
    return this.httpClient.get<any>(url, this.httpOptions)
  }

  public getResult(id: string): Observable<IApplicationResult> {
    const url = `${environment.loanServiceUrl}/result/${id}`
    return this.httpClient.get<IApplicationResult>(url, this.httpOptions)
  }

  /**
   * Look for existing promise of this person
   * @param personNummer - The personnummer
   */
  public check(personNummer: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/promise/check/${personNummer}`
    return this.httpClient.get<any>(url, this.httpOptions)
  }

  public delete(id: string): Observable<void> {
    const url = `${environment.loanServiceUrl}/admin/${id}`
    return this.httpClient.delete<void>(url, this.httpOptions).pipe(
      map(() => {
        this.loanPromises = this.loanPromises.filter(lp => lp['loan-promise-id'] !== id)
        this.loanPromiseList.next(this.loanPromises)
        return
      })
    )
  }

  public getDocumentLink(id: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/documents/${id}`
    return this.httpClient.get<any>(url, this.httpOptions)
  }

  public list(): Observable<Array<LoanPromiseListItem>> {
    const url = `${environment.loanServiceUrl}/promise/list?from=${this.lastFetchTime}`
    return this.httpClient.get<LoanPromiseListItem[]>(url, this.httpOptions)
      .pipe(
        map(list => {
          this.loanPromises = list
          this.loanPromiseList.next(this.loanPromises)
          this.lastFetchTime = new Date().getTime()
          this.getUsers()
          return this.loanPromises
        })
      )
  }

  /**
   * Update who is assigned
   */
  public update(id: string, data: ILoanPromiseAdmin): Observable<LoanPromiseListItem> {
    const url = `${environment.loanServiceUrl}/admin/${id}`
    return this.httpClient.put<LoanPromiseListItem>(url, data, this.httpOptions)
  }

  /**
   * Functions that are used by admin and should be moved to
   * an admin service eventually
   */

  /**
   * Get the UC as HTML
   */
  public getHtml(personNummer: string): Observable<any> {
    const url = `${environment.loanServiceUrl}/reports/${personNummer}`
    return this.httpClient.get<Array<any>>(url, this.httpOptions)
  }

  /**
   * Finalize application - Called to run the application flow for an application
   * sent without BankID.
   */
  public finalizeApplication(application: any): Observable<any> {
    const url = `${environment.loanServiceUrl}/promise/finalize`
    return this.httpClient.post<Array<any>>(url, application, this.httpOptions)
  }

  /**
   * Checks the auth token for admin. This is basically just
   * for UI changes, so we do not verify that it is a real token
   * all must be verified in backend anyway.
   */
  private isAdmin(): void {
    // Reset all fancy features.
    this.spbConfiguration.admin = false

    const token = this.httpOptions.headers.get('Authorization')
    try {
      const payload: Payload = HelperService.GetTokenPayload(token) as Payload
      this.spbConfiguration.admin = payload.admin === true
      if (payload.roles) {
        this.spbConfiguration.admin = payload.roles.indexOf('admin') !== -1
      }
      if (!this.spbConfiguration.admin) {
        this.spbConfiguration.mockUC = false // Turn off UC if not
      }
    } catch (e) {
      // Could happen and is quite normal.
    }
    this.configuration.next(this.spbConfiguration)
  }

  /**
   * Fetch a list of users, send them on the sub if not already
   * fetched.
   */
  private getUsers(): void {
    const url = `${environment.commonServiceUrl}/users`
    if (this.users$.getValue().length === 0) {
      this.httpClient.get<IUser[]>(url, this.httpOptions).subscribe((users: IUser[]) => {
        const filtered = users.filter(u => {
          return u.roles.indexOf('admin') !== -1 && u.roles.indexOf('developer') === -1
        })
        this.users$.next(filtered)
      })
    }
  }
}
