<div>
  <div class="top-holder">
    <div class="top">
      <div (click)="navigate('https://internetbank.sparbankensyd.se/')" class="internetbanken">
        <div>
          <em class="material-icons">
            lock_outline
          </em>
        </div>
        <div>
          Internetbanken
        </div>
      </div>
    </div>
  </div>
  <div class="middle-holder">
    <div class="middle">
      <div (click)="navigate('https://www.sparbankensyd.se/')" class="logo">
        Sparbanken Syd
      </div>
      <div class="filler"></div>
      <div *ngIf="isAdmin" class="menu-button">
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button [matMenuTriggerFor]="reset" mat-menu-item>Reset</button>
          <button [matMenuTriggerFor]="configuration" mat-menu-item>Configuration</button>
          <button [matMenuTriggerFor]="preset" mat-menu-item>Presets</button>
          <button [routerLink]="['/admin']" mat-menu-item>Admin</button>
        </mat-menu>
        <mat-menu #reset="matMenu">
          <button (click)="resetItem('application')" mat-menu-item>Application</button>
          <button (click)="resetItem('auth')" mat-menu-item>Auth</button>
          <button (click)="resetItem('personnummer')" mat-menu-item>Personnummer</button>
        </mat-menu>
        <mat-menu #preset="matMenu">
          <button (click)="preFill(0)" mat-menu-item>Approved</button>
          <button (click)="preFill(1)" mat-menu-item>Deny (KALP)</button>
          <button (click)="preFill(2)" mat-menu-item>Deny (UC)</button>
        </mat-menu>
        <mat-menu #configuration="matMenu">
          <button (click)="loanService.toggleUC()" mat-menu-item>
            <mat-icon *ngIf="mockUC">link</mat-icon>
            <mat-icon *ngIf="!mockUC">link_off</mat-icon>
            <span>Mock UC</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
